<template>
  <div>
    <ek-dialog
      ref="dialog"
      @close="resetForm"
      btnText="وظيفة جديدة"
      title="وظيفة جديدة"
      @ok="submitForm"
    >
      <template #body>
        <div>
          <ValidationObserver ref="jobForm">
            <b-form>
              <ek-input-select
                :options="activitiesList"
                v-model="job.senderId"
                :rules="[{ type: 'required' }]"
                name="اسم الفعالية"
                label="اسم الفعالية"
              >
              </ek-input-select>

              <ek-input-text
                readonly
                v-if="job.senderId"
                :value="
                  activitiesList.find((el) => el.id == job.senderId).commercialName || ''
                "
                name=" النشاط التجاري"
                label="النشاط التجاري"
              >
              </ek-input-text>
              <ek-input-select
                v-model="job.countryId"
                textLabel="langName"
                name=" الدولة"
                :options="countries"
                label="الدولة"
              >
              </ek-input-select>
              <ek-input-select
                v-if="
                  job.countryId &&
                  countries.find((el) => el.id == job.countryId).langName == 'سوريا'
                "
                textLabel="langName"
                v-model="job.cityId"
                :options="cities"
                name=" المدينة"
                label="المدينة"
              >
              </ek-input-select>

              <ek-input-select
                :options="jobTypes"
                v-model="job.jobType"
                :rules="[{ type: 'required' }]"
                name="نوع العمل"
                label="نوع العمل"
              >
              </ek-input-select>
              <ek-date-picker
                v-model="job.deadLineDate"
                :rules="[{ type: 'required' }]"
                name="اخر موعد للتقديم"
                label="اخر موعد للتقديم"
                placeholder="اختر تاريخ نهاية التسليم"
              >
              </ek-date-picker>
              <ek-input-text
                :rules="[{ type: 'required' }]"
                name="المسمى الوظيفي"
                label="المسمى الوظيفي"
                placeholder="ادخل المسمى الوظيفي"
                v-model="job.name"
              >
              </ek-input-text>
              <ek-input-text
                v-model="job.extraUrl"
                :rules="[{ type: 'required' }, { type: 'url' }]"
                name="رابط التقدم للوظيفة"
                label="رابط التقدم للوظيفة"
                placeholder="ادخل المسمى الوظيفي"
              >
              </ek-input-text>
              <ek-input-textarea
                v-model="job.discription"
                :rules="[{ type: 'required' }]"
                name="النص"
                label=" النص"
                placeholder="ادخل نص الوظيفة"
              ></ek-input-textarea>
            </b-form>
          </ValidationObserver>
        </div>
      </template>
    </ek-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    job: {
      senderId: "",
      discription: "",
      extraUrl: "",
      name: "",
      jobType: "",
      deadLineDate: "",
    },
  }),

  methods: {
    ...mapActions(["addJob"]),
    submitForm() {
      this.$refs.jobForm.validate().then((suc) => {
        if (suc) {
          this.addJob(this.job);
          this.$refs.dialog.close();
        }
      });
    },
    resetForm() {
      this.$refs.jobForm.reset();
      Object.assign(this.job, {
        senderId: "",
        discription: "",
        extraUrl: "",
        name: "",
        jobType: "",
        deadLineDate: "",
      });
    },
  },
  computed: {
    ...mapState({
      activitiesList: ({ activities }) => activities.activities,
      jobTypes: ({ jobs }) => jobs.jobTypes,
      cities: ({ locations }) => locations.cities,
      countries: ({ locations }) => locations.countries,
    }),
  },
};
</script>
